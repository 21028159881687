<style scoped>
    :deep(.article-container) {
        display: flex;
        justify-content: space-between;
        max-width: 15vw;
    }

    :deep(#articles-list .inputeo) {
        margin: 0px 10px;
        width: 100px;
    }

    .article-detail-container {
        display: flex;
        align-items: center;
        flex: 1;
        justify-content: space-between;
    }

    .article-detail-container div:nth-child(1) {
        flex: 2;
    }

    .article-detail-container div:nth-child(2) {
        flex: 1;
    }
</style>

<template>
    <CustomTopbar>
        <template v-slot:custom-topbar>
            <div id="topbar-button-container">
                <BaseButton buttonText="Créer un devis client" @click="displayCreateCustomerQuotationModal">>
                    <template v-slot:iconBefore>
                        <font-awesome-icon :icon="['fas', 'plus']" />
                    </template>
                </BaseButton>
            </div>
        </template>
    </CustomTopbar>
    <Aggrid
        apiRoute="orderDocument/list"
        :apiParams="filter"
        :columnDefs="columnDefs"
        :frameworkComponents="frameworkComponents"
        :valueFormatterFunctions="valueFormatterFunctions"
        :context="context"
        aggridHeightSubstract="100px"
        @rowClicked="(event) => editCustomerQuotation(event)"
    >
        <template v-slot:filter>
            <div>
                <BaseInput v-model="search" label="Rechercher un devis" type="text" name="search" @onChange="onSearchInputChange">
                    <template v-slot:iconBefore>
                        <font-awesome-icon :icon="['fas', 'search']"/>
                    </template>
                </BaseInput>
            </div>
        </template>
    </Aggrid>
    <OrderDocumentCreateModal
        mode="quotation"
        title="CRÉER UN DEVIS CLIENT"
        :displayModal="displayModalCreateCustomerQuotation"
        @onOrderDocumentCreated="onCustomerQuotationCreated"
        @onClose="() => { displayModalCreateCustomerQuotation = false }"
    ></OrderDocumentCreateModal>
    <OrderDocumentModal
        v-if="orderDocuments"
        v-model:orderDocuments="orderDocuments"
        v-show="displayOrderDocumentModal"
        :orderDocumentId="currentQuotationId"
        @onClose="closeOrderDocumentModal"
        @onOrderDocumentUpdated="onQuotationUpdated"
    ></OrderDocumentModal>
</template>

<script>
    import CustomTopbar from '../../components/Topbar/CustomTopbar';
    import BaseButton from '../../components/Base/BaseButton';
    import BaseInput from '../../components/Base/BaseInput'
    import Aggrid from '../../components/Aggrid/Aggrid'
    import ListQuotationsActionButtonsRenderer from './ListQuotationsActionButtonsRenderer.vue'
    import ListStatusWithIconRenderer from "./ListStatusWithIconRenderer.vue";
    import {formatPrice} from "../../utils/formatter";
    import config from "../../config";
    import OrderDocumentModal  from '../OrderDocument/OrderDocumentModal';
    import OrderDocumentCreateModal from '../OrderDocument/OrderDocumentCreateModal';
    import axios from 'axios';

    export default {
        name: 'Quotations',
        components: {
            CustomTopbar,
            BaseButton,
            BaseInput,
            ListQuotationsActionButtonsRenderer,
            Aggrid,
            OrderDocumentModal,
            OrderDocumentCreateModal
        },
        data() {
            return {
                columnDefs: [
                    {field: 'orderDocumentNumber', headerName: `Numéro`, flex: 1, lockPosition: true},
                    {field: 'affairNumber', headerName: `Affaire`, flex: 1, lockPosition: true, customFilter: 'affair.affairNumber'},
                    {field: 'billingName', headerName: `Client`, flex: 1, lockPosition: true},
                    {field: 'label', headerName: `Libellé`, flex: 1, lockPosition: true, wrapText: true},
                    {field: 'amount', headerName : `Montant`, flex: 1, lockPosition: true, valueFormatterFunctionName: 'AmountValueFormatterFunction', filter: 'agNumberColumnFilter' },
                    {headerName: 'Statut', flex: 1, cellRenderer: 'ListStatusWithIconRenderer', lockPosition: true, suppressMenu: true},
                    {field: 'refuseReason', headerName: `Raison refus`, flex: 1, lockPosition: true},
                    {headerName: '', width: 220, cellRenderer: 'ListQuotationsActionButtonsRenderer', suppressMenu: true}
                ],
                frameworkComponents: {
                    ListQuotationsActionButtonsRenderer: ListQuotationsActionButtonsRenderer,
                    ListStatusWithIconRenderer: ListStatusWithIconRenderer
                },
                valueFormatterFunctions: new Map([
                    [
                        'AmountValueFormatterFunction',
                        function(params){
                            return formatPrice(params?.data?.amount);
                        }
                    ]
                ]),
                context: null,
                search: '',
                searchTimeout: null,
                filter: {
                    mode: 'quotation',
                    deleted: false,
                    search: '',
                    validated: false
                },
                formatPrice: formatPrice,
                displayModalCreateCustomerQuotation: false,
                displayOrderDocumentModal: false,
                orderDocuments: null,
                currentQuotationId: null
            }
        },
        beforeMount() {
            this.context = {
                componentParent: this
            };
        },
        mounted() {
            if (this.$route.query?.action === 'displayOrderDocument') {
                this.editCustomerQuotation({
                    id: parseInt(this.$route.query.orderDocumentId),
                    affairId: parseInt(this.$route.query.affairId)
                });
            }
        },
        methods: {
            downloadQuotationInPdf(quotationPath) {
                if (quotationPath !== null) window.open(`${config.BACK_URL}/${config.UPLOAD_DIRECTORY}/${quotationPath}`, '_blank').focus();
            },
            onSearchInputChange(input) {
                if (this.searchTimeout !== null) {
                    clearTimeout(this.searchTimeout);
                }

                this.searchTimeout = setTimeout(() => {
                    this.filter.search = input.value
                }, 250);
            },
            displayCreateCustomerQuotationModal() {
                this.displayModalCreateCustomerQuotation = true;
            },
            onCustomerQuotationCreated(orderDocuments) {
                this.displayModalCreateCustomerQuotation = false;
                this.orderDocuments = orderDocuments;
                this.displayOrderDocumentModal = true;
                this.emitter.emit('resizeHeight');
            },
            closeOrderDocumentModal() {
                this.displayOrderDocumentModal = false;
            },
            onQuotationUpdated() {
                this.emitter.emit('ag-grid-reload');
            },
            editCustomerQuotation(quotation) {
                axios
                .get(`orderDocument/getSameOrderDocuments?mode=quotation&${quotation.affairId ? `affairId=${quotation.affairId}` : `orderDocumentId=${quotation.id}`}`, {
                    toastError: true,
                    showPreloader: true
                })
                .then(result => {
                    this.currentQuotationId = quotation.id;
                    this.onCustomerQuotationCreated(result.data);
                })
                .catch(() => {});
            }
        }
    }
</script>